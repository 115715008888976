import React from "react";
import styled from "styled-components";
import { withPage } from "../Page";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import * as Widget from "../Components/Widget";
import * as L from "../Utils/Lang";
import { d2s } from "../Utils/DateUtil";
import Link from "../Components/Link";
import Lightbox from "../Components/Lightbox";
import BlogItem from "../Components/BlogItem";
import Constants from "../Domain/constants";
import {
  SquareItemsFlex,
  SquareItemsWrapper,
  DisplayNameOverlay,
} from "../Components/SquareItem";
import { Helmet } from "react-helmet";

class ArchivePage extends React.Component {
  render() {
    let { blogs, magazines, category, item } = this.props;

    return (
      <Wrapper>
        <Helmet>
        <meta property="og:title" content="麒鉅設計 Chigi design" />
            <meta property="og:description" content="" />
            <meta
            property="og:image"
            content={`${
              item.images.length > 0
                ? item.images[0].image
                : `${Constants.webUrl}/images/pages/home/home_banner_2.jpg`}`}
            />
        </Helmet>
        <div className="share-panel">
          <div className="badge">
            <a
              href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
                `${Constants.webUrl}${this.props.location.pathname}`
              )}`}
              target="_blank"
            >
              {/* <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> */}
              <img
                src={"/images/icon-facebook-badge.png"}
                alt="fb-share-button"
              />
            </a>
          </div>
          <div className="badge">
            <a
              href={`https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(
                `${Constants.webUrl}${this.props.location.pathname}`
              )}`}
              target="_blank"
            >
              <img
                src={"/images/icon-line-badge.png"}
                alt="line-share-button"
              />
            </a>
          </div>
        </div>
        <Widget.MaxWidth width={1000} extraCss="width: 100%;">
          <h1>{L.d(item, "display_name")}</h1>
          <h3>{d2s(item.created)}</h3>
          <Content>
            {item.images.length > 0 && (
              <div>
                <div style={{ height: 300 }}>
                  <Widget.BgImage image={item.images[0].image} />
                </div>
                <Lightbox
                  centerImage
                  imagesArray={item.images.map((e) => ({
                    name: e.name,
                    image: e.image,
                  }))}
                />
              </div>
            )}

            {(L.d(item, "description") || L.d(item, "detail_content")) && (
              <div className="description">
                <div
                  dangerouslySetInnerHTML={{
                    __html: L.d(item, "detail_content"),
                  }}
                />
              </div>
            )}
          </Content>

          {/* id 1, 8 is blog vategory */}
          {item.categories.findIndex(
            (category) => [1, 8].indexOf(category.id) !== -1
          ) >= 0 ? (
            <AppendixSection>
              <h2>繼續閱讀</h2>
              {this._suffleArray(
                (item.categories.find((c) => c.id === 1) ? blogs : magazines)
                  .slice()
                  .filter((blog) => blog.id !== item.id)
              )
                .filter((_, idx) => idx < 5)
                .map((blog) => (
                  <BlogItem item={blog} />
                ))}
            </AppendixSection>
          ) : (
            <AppendixSection>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 40,
                }}
              >
                <Link to={"/contact"}>
                  <Widget.FlatButton
                    backgroundColor={"#705577"}
                    hoverColor={"#705577"}
                    style={{ color: "white", margin: "0px 50px" }}
                    labelStyle={{ verticalAlign: "bottom" }}
                    label={L.s("contact")}
                  />
                </Link>
              </div>
              <h2>相關作品</h2>
              <Section>
                {category &&
                  category.own_items
                    .slice()
                    .sort((a, b) => new Date(b.created) - new Date(a.created))
                    .filter((own_item, idx) => {
                      let findItemIndex = category.own_items
                        .slice()
                        .sort(
                          (a, b) => new Date(b.created) - new Date(a.created)
                        )
                        .findIndex((a) => a.id === item.id);
                      if (own_item.id === item.id) {
                        return false;
                      } else if (idx === findItemIndex - 1) {
                        return true;
                      } else if (idx === findItemIndex - 2) {
                        return true;
                      } else if (idx === findItemIndex - 3) {
                        return true;
                      } else if (idx === findItemIndex + 1) {
                        return true;
                      } else if (idx === findItemIndex + 2) {
                        return true;
                      } else if (idx === findItemIndex + 3) {
                        return true;
                      }
                    })
                    .filter((_, idx) => idx < 3)
                    .map((item, idx) => (
                      <SquareItemsFlex key={idx}>
                        <SquareItemsWrapper image={item.image}>
                          <Link
                            to={`/archive/${item.id}`}
                            extraStyle={{
                              width: "100%",
                              height: "100%",
                              textDecoration: "none",
                            }}
                          >
                            <DisplayNameOverlay text={item.display_name_tw} />
                          </Link>
                        </SquareItemsWrapper>
                      </SquareItemsFlex>
                    ))}
              </Section>
            </AppendixSection>
          )}
        </Widget.MaxWidth>
      </Wrapper>
    );
  }

  _suffleArray = (array) => {
    for (let i = 0; i < array.length; i++) {
      let j = Math.floor(Math.random() * (i + 1));
      let temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };
}

const Wrapper = styled.div`
  display: flex;
  padding: 40px 20px 20px 20px;
  & > div {
    & > h1 {
      text-align: center;
    }

    & h2 {
      padding: 20px 0px;
    }

    & > h3 {
      color: #959595;
      text-align: center;
    }
  }

  & > .share-panel {
    position: fixed;
    right: 10px;
    bottom: 60px; /* offset 50px for global messenger button */

    & .badge {
      border-radius: 50%;
      margin-top: 10px;
      & a {
        display: block;
      }
      & img {
        width: 40px;
        height: 40px;
        display: block;
      }
    }
  }
  @media screen and (max-width: 700px) {
    padding: 15px 10px 10px 10px;
    & > div {
      & > h1 {
        text-align: center;
      }
      & > h3 {
        color: #959595;
        text-align: center;
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  & > div {
    white-space: pre-wrap;
    /* padding-right: 10px; */
  }

  & > figure {
    flex-shrink: 0;
  }

  & > div.description {
    line-height: 1.5;
    width: 100%;

    img {
      max-width: 100% !important;
      height: auto !important;
    }

    p {
      line-height: 200%;
      word-wrap: break-word;
    }

    span {
      line-height: 200%;
    }
  }

  @media screen and (max-width: 700px) {
    margin: 15px;

    & > div.description {
      line-height: 1.5;
      & > div {
        margin: 20px 0px;
        max-width: 100%;
        padding: 10px;
      }
    }

    & > figure {
      margin: 0 auto;
      width: 100vw;
    }
  }
`;

const AppendixSection = styled.div`
  @media screen and (max-width: 700px) {
    & h2 {
      margin: 15px;
    }
  }
`;

const Section = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 540px) {
    justify-content: center;
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      //blog id fix to 1
      blogs: Selectors.getItemsByCategoryId(state, 1),
      //magazine id fix to 8
      magazines: Selectors.getItemsByCategoryId(state, 8),
      category: (() => {
        let categories = Selectors.getChigiCategories(state, 0);
        let findIndex = categories.findIndex((category) => {
          let findItemIndex = category.own_items.findIndex(
            (item) => item.id === ownProps.pageContext.itemId
          );
          return findItemIndex >= 0;
        });
        return categories[findIndex];
      })(),
      item: Selectors.getItemById(state, ownProps.pageContext.itemId),
    }),
    ActionCreator
  )(ArchivePage)
);
