import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import ActionCreator from '../ActionCreator';
import * as Widget from './Widget';
import * as L from '../Utils/Lang';
import { d2s } from '../Utils/DateUtil';

class BlogItem extends Component {
  render() {
    let { item, navActions } = this.props;
    return (
      <Wrapper onClick={() => navActions.push(`/archive/${item.id}`)}>
        <Picture>
          <Widget.BgImage image={item.image} />
        </Picture>

        <Widget.Col extraCss="padding-bottom: 25px; flex-grow: 1;">
          <Title>{L.d(item, 'display_name')}</Title>
          <Description>{L.d(item, 'description')}</Description>
        </Widget.Col>
        <Datebox>
          <div>{d2s(item.created)}</div>
        </Datebox>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  position: relative;
  display: flex;
  padding: 20px;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

let Picture = styled.div`
  margin-right: 10px;
  width: 220px;
  height: 220px;
  flex-shrink: 0;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

let Title = styled.h2``;

let Description = styled.p`
  line-height: 1.5;
  margin: 5px;
  max-height: 147px;
  overflow: hidden;
`;

let Datebox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    min-width: 100px;
    height: 20px;
    position: absolute;
    color: #a2a2a2;
  }
`;

export default connect(
  null,
  ActionCreator
)(BlogItem);
